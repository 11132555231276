import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useUser } from '../../hooks/user';

import {
  Content,
  FeaturedStore,
  FeaturedStoreCards,
  Sections,
  FeaturedMenu,
  Baners,
  News,
  FeaturedBestStores,
} from '../../styles/pages/home/styles';

import Rarrow from '../../assets/Homeicons/R-arrow.svg';
import Larrow from '../../assets/Homeicons/L-arrow.svg';
import Visibility from '../../assets/Homeicons/visibility.svg';
import VisibilityOff from '../../assets/Homeicons/visibility_off.svg';
import Analises from '../../assets/Homeicons/Analises.svg';
import Cashback from '../../assets/Homeicons/Cashback.svg';
import CompraCrypto from '../../assets/Homeicons/CompraCrypto.svg';
import Noticias from '../../assets/Homeicons/Noticias.svg';
import Wallet from '../../assets/Homeicons/Carteira.svg';
import Banner from '../../assets/Homeicons/banner_club.png';
import { useHome } from '../../hooks/home';
import { Title, StoreCard, Card, Button } from '../../components/UI';
import {
  AdvertiserListItem as AdvertiserModel,
  CarrouselImage,
} from '../../models/home';
import { UserBalance } from '../../models/user';
import Newscontainer from '../../components/NewsContainer';
import Head from 'next/head';
import Link from 'next/link';
import Container from 'next/app';

const OldHome: React.FC = () => {
  const { getUserBalance } = useUser();
  const { getCarouselImages, getAdvertisers } = useHome();
  const router = useRouter();

  const contentWrapper = React.useRef(null);
  const contentMid = React.useRef(null);
  const contentLast = React.useRef(null);

  const [featuredStores, setFeaturedStores] = useState<AdvertiserModel[]>([]);
  const [highlightsStores, setHighlightsStores] = useState<AdvertiserModel[]>(
    []
  );

  const [carouselImages, setCarouselImages] = useState<CarrouselImage[]>([]);
  const [isLoadingCarousel, setIsLoadingCarousel] = useState(true);
  const [isLoadingStoreCardsDefault, setIsLoadingStoreCardsDefault] = useState(
    true
  );
  const [isLoadingStore, setIsLoadingStore] = useState<number>();

  const bannersTop = [
    {
      image: 'https://cointimes.com.br/wp-content/uploads/2023/07/Inserir-um-titulo-4.png',
      link: 'https://chrome.google.com/webstore/detail/cointimes/cbohbbfalnakfabmclapcglchjnpljdm?hl=pt-BR',
      backgroundColor: '#000000'
    },
    {
      image: 'https://cointimes.com.br/wp-content/uploads/2023/11/comunidade_whatsapp.jpeg',
      link: 'https://chat.whatsapp.com/GmfEVpGlZ0jJpws2Sjc21b',
      backgroundColor: '#a3fac5'
    },
  ];

  const mobileBannerTop = {
    image: 'https://cointimes.com.br/wp-content/uploads/2023/11/comunidade_whatsapp.jpeg',
    link: 'https://chat.whatsapp.com/GmfEVpGlZ0jJpws2Sjc21b',
    backgroundColor: '#a3fac5'
  };

  function getRandomBannerTop()
  {
    return bannersTop[Math.floor(Math.random()*bannersTop.length)]
  }

  const [bannerTopUrl, setBannerTopUrl] = useState(getRandomBannerTop());

  useEffect(() => {
    async function getInitialData() {
      try {
        const carouselImagesData = await getCarouselImages();
        const featuredStoresData = await getAdvertisers(19, 0);
        const highlightsStoresData = await getAdvertisers(
          19,
          0,
          'home-featured'
        );

        setCarouselImages(carouselImagesData);
        setFeaturedStores(featuredStoresData);
        setHighlightsStores(highlightsStoresData);

        setIsLoadingCarousel(false);
        setIsLoadingStoreCardsDefault(false);
      } catch (error) {
        console.log(error);
      }
    }

    getInitialData();

    const isMobile = localStorage.getItem('@coingoback:isMobile') === 'true';
    if (isMobile) {
      setBannerTopUrl(mobileBannerTop);
    }else{
      setInterval(() => {
          setBannerTopUrl(getRandomBannerTop());
      }, 8000);
    }
  }, []);

  const handleStoreClick = (slug: string) => {
    router.push({
      pathname: `store/${slug}`,
    });
  };

  const sideScroll = (
    element: any,
    speed: number,
    distance: number,
    step: number
  ) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };

  const [showElement, setShowElement] = useState(true);
  const showOrHide = () => setShowElement(!showElement);

  return (
    <>
      <Head>
        <title>Ganhe bitcoin | Cointimes</title>
        <meta
          name="description"
          content="Ganhe bitcoin nas lojas parceiras com criptoback em cashback nas suas compras no Cointimes. Aproveite para ganhar bitcoin de volta agora!"
        />
      </Head>
      <Sections>
        <Content backgroundColor={bannerTopUrl.backgroundColor}>
          <a target="_blank" href={bannerTopUrl.link}>
            <img
              src={bannerTopUrl.image}
              alt="banner"
            />
          </a>
        </Content>
        <FeaturedMenu>
          <div className="title body">
            <Title>O que você está procurando?</Title>
            <div className="arrows">
              <button
                onClick={() => {
                  sideScroll(
                    contentWrapper.current,
                    20,
                    contentWrapper.current.offsetWidth,
                    -20
                  );
                }}
              >
                <Larrow />
              </button>
              <button
                onClick={() => {
                  sideScroll(
                    contentWrapper.current,
                    20,
                    contentWrapper.current.offsetWidth,
                    20
                  );
                }}
              >
                <Rarrow />
              </button>
            </div>
          </div>
          <div ref={contentWrapper} className="content">
            <div className="cards">
              <button
                onClick={() => {
                  router.push('/stores');
                }}
              >
                <Card.Box className="menu card">
                  <Cashback />
                  <span> Criptoback </span>
                </Card.Box>
              </button>
              <button
                onClick={() => {
                  router.push('/news');
                }}
              >
                <Card.Box className="menu card">
                  <Noticias />
                  <span> Notícias</span>
                </Card.Box>
              </button>
              <button
                onClick={() => {
                  router.push('/market');
                }}
              >
                <Card.Box className="menu card">
                  <Analises />
                  <span> Mercado </span>
                </Card.Box>
              </button>
              <button
                onClick={() => {
                  router.push('/stores?tag=buy-crypto');
                }}
              >
                <Card.Box className="menu card">
                  <CompraCrypto />
                  <span>
                    Compre <br /> e ganhe
                  </span>
                </Card.Box>
              </button>

              <button
                onClick={() => {
                  router.push('/cashout');
                }}
              >
                <Card.Box className="menu card">
                  <Wallet />
                  <span> Sacar </span>
                </Card.Box>
              </button>

              <a
                href="https://club.cointimes.com.br"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Card.Box className="menu card">
                  <Analises />
                  <span> Educação </span>
                </Card.Box>
              </a>
            </div>
          </div>
        </FeaturedMenu>
        <FeaturedBestStores>
          <div className="title body">
            <Title>As maiores lojas com criptoback</Title>
            <div className="arrows">
              <button
                onClick={() => {
                  sideScroll(
                    contentLast.current,
                    20,
                    contentLast.current.offsetWidth,
                    -20
                  );
                }}
              >
                <Larrow />
              </button>
              <button
                onClick={() => {
                  sideScroll(
                    contentLast.current,
                    20,
                    contentLast.current.offsetWidth,
                    20
                  );
                }}
              >
                <Rarrow />
              </button>
            </div>
          </div>

          <FeaturedStoreCards ref={contentLast}>
            {isLoadingStoreCardsDefault &&
              [...Array(19)].map((item, index) => (
                <StoreCard.Default key={index} isLoading />
              ))}
            {!isLoadingStoreCardsDefault &&
              featuredStores.slice(0, 19).map((store, index) => (
                <button
                  className="storecards"
                  key={index}
                  onClick={() => {
                    setIsLoadingStore(index), handleStoreClick(store.slug);
                  }}
                >
                  <StoreCard.Default
                    key={index}
                    store={store}
                    title={store.name}
                    loading={isLoadingStore === index}
                  />
                </button>
              ))}
            <div className="lastcard">
              {!isLoadingStoreCardsDefault && <StoreCard.LastCard />}
            </div>
          </FeaturedStoreCards>
          <div className="GoToStore">
            <button
              onClick={() => {
                router.push('/stores');
              }}
            >
              Ver todas lojas
            </button>
          </div>
        </FeaturedBestStores>
        <Content backgroundColor={'#a3fac5'}>
          <a target="_blank" href="https://chat.whatsapp.com/GmfEVpGlZ0jJpws2Sjc21b">
            <img
              src="https://cointimes.com.br/wp-content/uploads/2023/11/comunidade_whatsapp.jpeg"
              alt="banner"
            />
          </a>
        </Content>
        <FeaturedStore>
          <div className="title body">
            <Title>Criptoback em destaque</Title>
            <div className="arrows">
              <button
                onClick={() => {
                  sideScroll(
                    contentMid.current,
                    20,
                    contentMid.current.offsetWidth,
                    -20
                  );
                }}
              >
                <Larrow />
              </button>
              <button
                onClick={() => {
                  sideScroll(
                    contentMid.current,
                    20,
                    contentMid.current.offsetWidth,
                    20
                  );
                }}
              >
                <Rarrow />
              </button>
            </div>
          </div>
          <FeaturedStoreCards ref={contentMid}>
            {isLoadingStoreCardsDefault &&
              [...Array(19)].map((item, index) => (
                <StoreCard.Default key={index} isLoading />
              ))}
            {!isLoadingStoreCardsDefault &&
              highlightsStores.slice(0, 19).map((store, index) => (
                <button
                  className="storecards"
                  key={index}
                  onClick={() => {
                    setIsLoadingStore(index + 999),
                      handleStoreClick(store.slug);
                  }}
                >
                  <StoreCard.Default
                    key={index}
                    store={store}
                    title={store.name}
                    loading={isLoadingStore === index + 999}
                  />
                </button>
              ))}
            <div className="lastcard">
              {!isLoadingStoreCardsDefault && <StoreCard.LastCard />}
            </div>
          </FeaturedStoreCards>
        </FeaturedStore>

        {/* <Baners>
          <Card.Box className="frist"></Card.Box>
          <Card.Box className="second"></Card.Box>
        </Baners> */}
        <Newscontainer />
        <div className="redirect">
          <button
            onClick={() => {
              router.push('/news');
            }}
          >
            Quero aprender mais
          </button>
        </div>
      </Sections>
    </>
  );
};

export default OldHome;

import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 10px;

  .title {
    display: flex;
    justify-content: space-between;

    @media (max-width: 769px) {
      margin-left: 21px;
    }
  }

  .arrows {
    display: flex;
    align-self: center;
    padding: 0;
    width: 30px;
    justify-content: space-between;
    @media (max-width: 769px) {
      display: none;
    }
  }
  .contentNews {
    @media (min-width: 1089px) {
      width: 100vw;
    }
    width: 95vw;
    max-width: 1030px;
    display: flex;
    align-items: center;
    height: 350px;
    overflow: auto;
    ::-webkit-scrollbar {
      display: none;
    }
    @media (max-width: 769px) {
      width: 100vw;
    }

    @media (max-width: 425px) {
      height: 220px;
    }

    .rowImg {
      align-self: center;
      height: 148px;
      width: 80%;
      margin: 10px;
    }
    .rowTxt {
      align-self: center;
      height: 30px;
      width: 40%;
      margin: 10px;
    }
  }

  .cards {
    display: flex;
    gap: 20px;

    &:first-child {
      @media (max-width: 769px) {
        margin-left: 21px;
      }
    }

    ::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }

    .card {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      width: 230px;
      height: 278px;
      padding: 0;
      margin: 5px 0 0 10px;
      box-shadow: none;
      border-radius: 22px;

      font-weight: bold;
      font-size: 16px;
      text-align: center;

      background: #000;

      cursor: pointer;

      @media (max-width: 425px) {
        width: 152px;
        height: 185px;

        font-size: 12px;
      }

      img {
        width: 230px;
        min-height: 148px;
        max-height: 148px;
        object-fit: cover;
        border-radius: 22px 22px 0 0;
        margin: -8px;
        border: 1px solid;

        @media (max-width: 425px) {
          width: 152px;
          min-height: 100px;
          max-height: 100px;
        }
      }

      span {
        margin: 12px 20px;
        color: #fff;
        @media (max-width: 425px) {
          margin: 5px;
        }
      }

      &:last-child {
        margin-right: 10px;
      }
    }
  }
`;
